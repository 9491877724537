.search-product-upload {
    max-width: 1200px;
    min-width: 700px;
    width: max-content;
    margin: 0 auto;
    padding: 20px;
    margin-bottom: 200px;
}

.p-inputgroup {
    margin-bottom: 20px;
}
