.p-stepper .p-stepper-header.p-highlight .p-stepper-action {
    background-color: var(--primary-color);
    color: white;
}
.p-stepper .p-stepper-header.p-highlight .p-stepper-title {
    color: white;
}

.p-panel .p-panel-header {
    background-color: var(--primary-color);
    color: white;

}

.p-panel .p-panel-header .p-panel-header-icon {
    color: white;
}