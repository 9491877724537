.flex-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: white;
}

.button-group {
    display: flex;
    align-items: center;
}

.upload-button {
    margin-left: auto;
}