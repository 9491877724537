.p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(.p-datatable-tbody) .p-datatable-tbody>tr>td {
  font-size: 11px;
  padding: 0.25rem 1rem;
}


/* Overriding inline styles with !important */
.centered-spinner .p-progress-spinner {
  display: flex !important;
  /* Override the inline-block display from primeReact component*/
}

.container {
  max-width: 1440px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@font-face {
  font-family: 'Gotham Rounded Book';
  src: url('../public/GothamRounded-Book.otf') format('truetype');
  /* Add other font properties if needed */
}


.font-kroger {
  font-family: "Gotham Rounded Book";
}

.p-float-label input:focus~label {
  color: white;
}

.radio-switch.p-inputswitch[data-p-highlight="true"] .p-inputswitch-slider {
  background: #22c55e;
}

.intake-form-textSpan {
  display: block;
  width: 100%;
  height: 3rem;
  background-color: rgb(8, 73, 153) !important;
}

.s-title {
  font-size: 1.75rem;
  color: var(--primary-color);
  /* Example color for 'text-primary', replace with your project's primary color */
  font-family: "Proxima Nova", sans-serif;
  padding-left: "10px"
}

.Syndigo-Submit-Dialog {
  max-width: '85%';
  min-width: '50%';
  max-height: '85%';
  min-height: '50%';
  justify-content: auto;
  align-content: center;
  overflow: visible;
}

.Dialog-Spinner {
  max-width: '85%';
  min-width: '50%';
  max-height: '85%';
  min-height: '50%';
  justify-content: center;
  vertical-align: auto;
  margin: '100px';
  padding: '100px';
}

.custom-tooltip {
  max-width: 200px;
  /* Limit the width of the tooltip */
  margin-bottom: 10px;
  padding: 10px;
  /* Add padding for modern styling */
  color: #fff;
  /* White text color */
  border-radius: 8px;
  /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Soft shadow for depth */
  font-size: 0.9rem;
  /* Slightly smaller font size */
  line-height: 1.4;
  /* Improve readability */
}

.red-tooltip {
  .p-tooltip-text {
    background-color: #ff3d32;
  }

  .p-tooltip-arrow {
    border-top-color: #ff3d32;
    border-bottom-color: #ff3d32;
  }
}

.hoverClass .p-tooltip-text {
  background-color: red !important;
}

.page-background {
  background: #084999;
  color: white;
  height: 100vh;
}

.min-width-scroll {
  min-width: 800px;
  overflow-x: hidden;
}

.min-width-scroll:has(.create-product-min-width) {
  /* Added so when a child element requires a bigger min width, it will apply to top level div */
  min-width: 1000px;
}

.create-product-min-width {
  min-width: 1000px;
}

.p-chips-multiple-container {
  width: 100% !important;
}

.custom-error-panel .p-panel-header {
  background-color: #f44336 !important;
  /* Error red background */
  color: white;
  display: flex;
  align-items: center;
}

.custom-error-panel .p-panel-title {
  flex: 1;
  text-align: left;

}

.custom-error-panel .p-panel-header-icon {
  position: relative;
  left: 1rem;
  margin-right: 1rem;
  color: white;
  background-color: #f44336;
}

.custom-error-panel .p-panel-toggler {
  color: white;
}

.custom-error-panel .p-panel-content {
  padding: 0.5rem;
  text-align: left;
}

.custom-error-panel .error-text {
  margin: 0;
  white-space: normal;
  overflow-wrap: break-word;
  word-break: break-word;
}