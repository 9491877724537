.syndigo-create-product {
    margin: 0 auto;
    padding: 20px;
    margin-bottom: 200px;
    overflow: hidden;
}

.create-panel {
    .p-panel-header {
        background-color: white;
    }

    .p-panel-toggler {
        background-color: white;

        .p-icon {
            color: #084999;
        }
    }

    .p-panel-title {
        color: #084999;
    }

    .p-panel-content {
        height: 200px;
        .p-fileupload .p-fileupload-content {
            border: none;
        }
    }
}

.create-import {
    .p-panel-content {
        padding: 0px;
    }
}

.create-file-upload {
    .p-fileupload-content {
        padding: 0;
    }
}

.create-table-column {
    width: 1rem;
    white-space: nowrap;
    /* Prevent text from wrapping */
    overflow: hidden;
    /* Hide overflow */
    text-overflow: ellipsis;
    /* Display ellipsis (...) if text overflows */
}

/* Status column to fit typical status messages */
.status-column {
    width: auto;
    min-width: 400px;
    max-width: 500px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}